import React from 'react'
import Hero from '../components/Hero'
import LatestPosts from '../components/latestPosts'
import {Helmet} from "react-helmet";
import { useQuery,gql} from '@apollo/client'
import ReactGA from 'react-ga4';
//import HOME from '../queries';

const HOME = gql`
query HOME{
  homepage{
    data{
      attributes{
        seo{
          metaTitle
          metaDescription
          metaViewport
          metaRobots
          structuredData
          keywords
          metaTwitterImage{
            data{
              attributes{
                url
              }
            }
          }
          metaImage{
            data{
              attributes{
                url
              }
            }
          }
          
        }
      }
    }
  }
}
`

export default function Homepage() {
    const { loading, error, data } = useQuery(HOME)

    
    if (loading) return <p>Loading</p>
    if (error) return <p>{JSON.stringify(error)}</p>
    ReactGA.send("pageview");

    

    return (
        <div>
          <Helmet>
              <meta charSet="utf-8" />
              <title>{data.homepage.data.attributes.seo.metaTitle}</title>
              <meta name="description" content={data.homepage.data.attributes.seo.metaDescription} />
              <link rel="canonical" href="/" />
              <meta name="keywords" content={data.homepage.data.attributes.seo.keywords}/>
              <meta name="author" content="Saumya Talwani"/>
              <meta property="og:title" content={data.homepage.data.attributes.seo.metaTitle} />
              <meta property="og:type" content="website" />
              <meta property="og:url" content="https://www.techsaumya.in/" />
              <meta property="og:image" content={data.homepage.data.attributes.seo.metaTwitterImage.data.attributes.url}/>
              <meta property="og:description" content={data.homepage.data.attributes.seo.metaDescription}/>
              <meta property="twitter:title" content="Home | TechSaumya" />
              <meta property="twitter:type" content="website" />
              <meta property="twitter:url" content="https://www.techsaumya.in/" />
              <meta property="twitter:image" content={data.homepage.data.attributes.seo.metaTwitterImage.data.attributes.url}/>
              <meta property="twitter:description" content={data.homepage.data.attributes.seo.metaDescription}/>
              <meta name="robots" content={data.homepage.data.attributes.seo.metaRobots}/>
              <script type="application/ld+json">{JSON.stringify(data.homepage.data.attributes.seo.structuredData)}</script>
          </Helmet>
          <Hero />
          <LatestPosts/>
        </div>
      )
    
  }