import { BrowserRouter as Router, Route, Routes  } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import Homepage from "./pages/Homepage";
import SiteHeader from "./components/SiteHeader";
import Post from "./pages/Post"
import PostGrid from "./pages/PostGrid"
import About from "./pages/About";
import SiteFooter from './components/footer'
import Srch from "./pages/search";
import ReactGA from 'react-ga4';

const client = new ApolloClient({
  uri: process.env.REACT_APP_BACKEND_URL,
  cache: new InMemoryCache()
})



function App() {
  ReactGA.initialize(process.env.REACT_APP_GA_TID)
  return (
    <Router>  
      <div className="App">
        <ApolloProvider client={client}>
        <SiteHeader />
        <Routes>
        <Route exact path="/" element={<Homepage />} />
        <Route exact path="/blogs/:slug" element={<Post />} />
        <Route exact path="/blogs" element={<PostGrid />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/search/:qry" element={<Srch />} />
        </Routes>
        </ApolloProvider>
      </div>
      <SiteFooter/>
    </Router>
    
  );
}

export default App;
