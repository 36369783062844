import React from 'react'
import Card from './Card'
import { useQuery,gql} from '@apollo/client'
//import { Link } from 'react-router-dom'
//import POSTS from '../queries'

const POSTS = gql`
query getPosts{
  articles{
    data{
      id,
      attributes{
        title,
        description,
        content,
        slug,
        publishedAt,
        tags{
          data{
            attributes{
              name
            }
          }
        }
        thumb{
          data{
            attributes{
              url,
              formats
            }
        }
      }
    }
  }
}
writer(id: 1){
  data{
    attributes{
      name,
      picture{
        data{
          attributes{
            url
          }
        }
      }
    }
  }
}
}`



export default function LatestPosts() {
    const { loading, error, data } = useQuery(POSTS)

    if (loading) return <p className='text-3xl'>Loading...</p>
    
    if (error) {console.log(error)
      return <p>Error: {JSON.stringify(error)}</p>;}

    var res = data.articles.data.slice().reverse().slice(0,4)
    //var last3=res.slice(0,4)
    //console.log(res)
    


    return(<div className='w-full top[90px] px-5 posts' id='latestPosts'>
        <div className='grid lg:grid-cols-3 gap-8 lg:gap-[10rem] pb-5 px-5'>
        {
          res.map(post => (
            <Card key={post.id} 
                  title={post.attributes.title}
                  desc={post.attributes.description} 
                  image={post.attributes.thumb.data.attributes.url} 
                  link={`/blogs/${post.attributes.slug}`}
                  ctg={post.attributes.tags.data[0].attributes.name}
            />
        ))}

    </div>
</div>)
}
