import React from 'react'
import { Link } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';
import { useQuery,gql } from '@apollo/client';

const LOGO = gql`
query logo{
  logo{
    data{
      attributes{
        logo{
          data{
            attributes{
              url
            }
          }
        }
      }
    }
  }
}
`


export default function SiteHeader() {
  const { loading, error, data } = useQuery(LOGO)

  if (loading) {
    var logo=null
  }
  //console.log("loading")
  if (error) {console.log(JSON.stringify(error))
    logo=null
  }
  if (data){
    logo=data.logo.data.attributes.logo.data.attributes.url
  }
  

  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <nav className="relative flex flex-wrap items-center justify-between px-2 sm:px-3 py-3 bg-white-100 mb-3">
        <div className="container px-2  mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-stretch logoText">

            <Link
              className="text-3xl font-bold leading-relaxed inline-block mr-0 py-3 whitespace-nowrap text-black nav"
              to="/">
            <img
              src={logo}
              alt='techsaumya logo'
              loading='lazy'
              title='techsaumya logo'
              className='h-[64px] inline-block leading-relaxed whitespace-nowrap mr-3 '
              />
              TechSaumya
            </Link>

            <button
              className="text-sky-50 hover:text-sky-400 cursor-pointer text-xl leading-none px-3 py-1 rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              {//<i className="fas fa-bars"></i>
              }
              <MenuIcon className=''/>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="nav-item logoText">
                <a
                  className="px-3 py-2 flex items-center text-md font-md leading-snug text-black hover:opacity-75"
                  href="/blogs"
                >
                  <span className="ml-2">Blogs</span>
                </a>
              </li>
              <li className="nav-item logoText">
                <a
                  className="px-3 py-2 flex items-center text-md font-md leading-snug text-black hover:opacity-75"
                  href="/about"
                >
                  <span className="ml-2">About</span>
                </a>
              </li>
              <li className="nav-item logoText">
                <a
                  className="px-3 py-2 flex items-center text-md font-md leading-snug text-black hover:opacity-75"
                  href="https://youtube.com/@techsaumya"
                >
                  <span className="ml-2">YouTube</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
  );
}