import React from 'react'
import { useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import { HashLink } from 'react-router-hash-link'
import { useQuery, gql } from '@apollo/client'
import DOMpurify from 'dompurify';
import rehypeRaw from "rehype-raw";
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga4';



const PST = gql`
query GetPost($slugid: String,$writerId: ID){
  articles(filters:{slug:{eq:$slugid}}){
    data{
      id,
      attributes{
        title,
        description,
        content,
        publishedAt,
        slug,
        thumb{
          data{
            attributes{
              url,
              alternativeText,
            }
          }
        },
        seo{
          metaTitle
          metaDescription
          metaViewport
          metaRobots
          structuredData
          keywords
          metaTwitterImage{
            data{
              attributes{
                url
              }
            }
          }
          metaImage{
            data{
              attributes{
                url
              }
            }
          }
          
        },
        writer{
          data{
          id,
          attributes{
            name
          }
        }
      }
    }
  }
}
writer(id: $writerId){
    data{
      attributes{
        name,
        picture{
          data{
            attributes{
              url
            }
          }
        }
      }
    }
  }
}`

const options = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

function formatMyDate(value, locale = 'en-GB') {
  return new Date(value).toLocaleDateString(locale,options);
}

export default function Post() {
    const { slug } = useParams()
    const writer=1;


    const { loading, error, data } = useQuery(PST,{
      variables: {slugid:slug,writerId:writer}
    })
   
    if (loading) return <p className='text-3xl'>Loading...</p>    
    if (error) return <p>Error: {JSON.stringify(error)}</p>;
    console.log(data)
    ReactGA.send("pageview");
    const img=data.writer.data.attributes.picture.data.attributes.url
      return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{data.articles.data[0].attributes.seo.metaTitle + ' | TechSaumya'}</title>
          <meta name="description" content={data.articles.data[0].attributes.seo.metaDescription} />
          <link rel="canonical" href={"/blogs/"+data.articles.data[0].attributes.slug} />
          <meta name="keywords" content={data.articles.data[0].attributes.seo.keywords}/>
          <meta name="author" content="Saumya Talwani"/>
          <meta property="og:title" content={data.articles.data[0].attributes.seo.metaTitle} />
          <meta property="og:type" content="article" />
          <meta property="og:url" content={"https://techsaumya.in/blogs/"+data.articles.data[0].attributes.slug} />
          <meta property="og:image" content={data.articles.data[0].attributes.seo.metaTwitterImage.data.attributes.url}/>
          <meta property="og:description" content={data.articles.data[0].attributes.seo.metaDescription}/>
          <meta property="twitter:title" content="Home | TechSaumya" />
          <meta property="twitter:type" content="article" />
          <meta property="twitter:url" content={"https://techsaumya.in/blogs/"+data.articles.data[0].attributes.slug} />
          <meta property="twitter:image" content={data.articles.data[0].attributes.seo.metaTwitterImage.data.attributes.url}/>
          <meta property="twitter:description" content={data.articles.data[0].attributes.seo.metaDescription}/>
          <meta name="robots" content={data.articles.data[0].attributes.seo.metaRobots}/>
          <script type="application/ld+json">{JSON.stringify(data.articles.data[0].attributes.seo.structuredData)}</script>
        </Helmet>
        <div className='w-[90vw] p-10 pr-5 link text-milk'>
          <h1 className='font-extrabold text-5xl pb-2 pstTtl'>{data.articles.data[0].attributes.title}</h1>
          <span className='text-md font-thin pb-10'>Published on {formatMyDate(data.articles.data[0].attributes.publishedAt)+''}<br/>
          by <img className='aspect-square rounded-full h-[24px] inline-block' alt='writer-thumbnail' src={img}/>
          <HashLink to={'/about#'+data.articles.data[0].attributes.writer.data.id}> {data.articles.data[0].attributes.writer.data.attributes.name}</HashLink></span>
          <ReactMarkdown rehypePlugins={[rehypeRaw]} className='text-lg font-normal py-10'>{DOMpurify.sanitize(data.articles.data[0].attributes.content)}</ReactMarkdown>  
        </div>
      
      </>
    )
  }