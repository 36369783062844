import React from 'react'
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom'

export default function SiteFooter() {
    return(
    <div className='flex w-full bg-charcoal-700 bottom-0 p-[30px] text-milk'>

    <div className="mr-[1.25rem] mb-[2rem] w-screen">
        <h2 className="text-2xl font-bold pb-5 text-sky-400">TechSaumya</h2>
        
        <h2>Contact</h2>
    
        <address>
        Gujarat,India<br/>
        <div className='pt-5'>
            <Link className="flex float-left clr m-2 ml-0" to="mailto:techsaumya@gmail.com"><MailIcon className='hover:text-sky-200'/></Link>
            <Link className="flex float-left clr m-2" to="https://www.instagram.com/techsaumya"><InstagramIcon className='hover:text-fuschia-400'/></Link>
            <Link className="flex float-left blu clr m-2" to="https://www.linkedin.com/company/techsaumya/"><LinkedInIcon className='hover:text-sky-500'/></Link>
            <Link className="flex float-left red clr m-2" to="https:/www.youtube.com/@techsaumya"><YouTubeIcon className='hover:text-cherry-500'/></Link>
        </div>
        </address>
    </div>
  <div className="w-[55vw] flex items-end justify-end">
    <p>&copy; 2023 TechSaumya. All rights reserved.</p>
    <div className="ml-5">
      <span>Made with <span className="heart">♥</span> in India</span>
    </div>
  </div>
        {/*
        <div className='w-full h-[40vh] flex flex-col justify-center px-5 relative text-white'>
            <h1 className='text-5xl font-bold pb-5'>Find Us</h1> 
            <p className='py-3'>Contact us through mail <a to="mailto:techsaumya@gmail.com">here</a></p>
            <div className='ctc'>
                <Link className='pr-5 clr' to="https://www.instagram.com/techsaumya"><InstagramIcon fontSize='large'/></Link>
                <Link className='pr-5 blu clr' to="https://www.linkedin.com/in/saumyatalwani"><LinkedInIcon fontSize='large'/></Link>
                <Link className='pr-5 red clr' to="https://www.youtube.com/@techsaumya"><YouTubeIcon fontSize='large'/></Link>
            </div>

        */}
        </div>
        )
}
