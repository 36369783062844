import LCard from "../components/LongCard";
import { useQuery, gql } from '@apollo/client'
import { useParams } from "react-router-dom";

const GETRESULT =gql`
query getSearchedPosts($query: String) {
articles(sort: "publishedAt:desc"
filters: { title: { containsi: $query } }) {
    data{
        id,
        attributes{
          title,
          description,
          content,
          slug,
          publishedAt,
          tags{
            data{
              attributes{
                name
              }
            }
          }
          thumb{
            data{
              attributes{
                url,
                formats
              }
          }
        }
      }
    }
  }
  writer(id: 1){
    data{
      attributes{
        name,
        picture{
          data{
            attributes{
              url
            }
          }
        }
      }
    }
  }
    }`

export default function Srch(){
    
    let { qry } = useParams();
    const { loading, error, data } = useQuery(GETRESULT,{
    variables:{query:qry}
    })
    
    if (loading) return <p className='text-3xl'>Loading...</p>
    if (error) return <p>Error: {JSON.stringify(error)}</p>;

    var searchResults=data.articles.data
    
      const preparePostPreviews = () => {
        if (searchResults.length > 0) {
          return searchResults.map((post) => (
            <LCard title={post.attributes.title} desc={post.attributes.description} ctg={post.attributes.tags.data[0].attributes.name} image={post.attributes.thumb.data.attributes.url} link={`/blogs/${post.attributes.slug}`}/>
          ));
        } else {
          return (
            <h4 className="font-mono text-black text-lg sm:col-span-2 lg:col-span-3 text-center">
              No results
            </h4>
          );
        }
      };

      return (
        <section className="my-8 mx-4">
          <h2 className="font-mono text-black text-xl md:text-4xl text-center mb-8">
            Search results for: &quot;{qry}&quot;
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 ">
            {searchResults ? (
              preparePostPreviews()
            ) : (LCard)
            }
          </div>
        </section>
      );
}