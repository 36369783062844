import React from 'react'
//import useDocumentTitle from '../components/useDocumentTitle'
import Writer from '../components/writer';
import {Helmet} from "react-helmet";
import { useQuery, gql } from '@apollo/client'
import ReactGA from 'react-ga4';

const ABOUT = gql`
query ABOUT{
  aboutpage{
    data{
      attributes{
        seo{
          metaTitle
          metaDescription
          metaViewport
          metaRobots
          structuredData
          keywords
          metaTwitterImage{
            data{
              attributes{
                url
              }
            }
          }
          metaImage{
            data{
              attributes{
                url
              }
            }
          }
          
        }
      }
    }
  }
}
`

export default function About() {
    //useDocumentTitle('About | TechSaumya')

    const { loading, error, data } = useQuery(ABOUT)

    if (loading) return <p>Loading</p>
    if (error) return <p>{JSON.stringify(error)}</p>
    ReactGA.send("pageview");
    return (
      <div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{data.aboutpage.data.attributes.seo.metaTitle}</title>
            <meta name="description" content={data.aboutpage.data.attributes.seo.metaDescription} />
            <link rel="canonical" href="/about" />
            <meta name="keywords" content={data.aboutpage.data.attributes.seo.keywords}/>
            <meta name="author" content="Saumya Talwani"/>
            <meta property="og:title" content={data.aboutpage.data.attributes.seo.metaTitle} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://techsaumya.in/about" />
            <meta property="og:image" content={data.aboutpage.data.attributes.seo.metaTwitterImage.data.attributes.url}/>
            <meta property="og:description" content={data.aboutpage.data.attributes.seo.metaDescription}/>
            <meta property="twitter:title" content={data.aboutpage.data.attributes.seo.metaTitle} />
            <meta property="twitter:type" content="website" />
            <meta property="twitter:url" content="https://techsaumya.in/about" />
            <meta property="twitter:image" content={data.aboutpage.data.attributes.seo.metaTwitterImage.data.attributes.url}/>
            <meta property="twitter:description" content={data.aboutpage.data.attributes.seo.metaDescription}/>
            <meta name="robots" content={data.aboutpage.data.attributes.seo.metaRobots}/>
            <script type="application/ld+json">{JSON.stringify(data.aboutpage.data.attributes.seo.structuredData)}</script>
        </Helmet>
        <div className='p-10 link'>
        <h1 className='font-bold text-4xl pb-10 text-sky-400 cgrot'>About Us</h1>
        <p className='pb-5'>TechSaumya was founded in 2016 by Saumya Talwani with <a href="/">techsaumya.weebly.com</a> (now taken down) and then we switched to <a href="https://techsaumya.blogspot.in">techsaumya.blogspot.in</a> in 2017. 
        In 2018 TechSaumya 3.0 with the <a className="link" href="http://www.t3chsaumya.wordpress.com">t3chsaumya.wordpress.com</a>.  
        In 2019, we switched to <a href="https://www.techsaumya.in">www.techsaumya.in</a>  
        . In March 2020, A <a href="https://www.youtube.com/@techsaumya">YouTube Channel</a> was also started with the same name. 
        On March 25th 2020 ,The First Video was posted  on the channel and received overwhelming support from the audience.</p>
        <h1 className='font-bold text-4xl pb-10 text-sky-400 cgrot'>The Team</h1>
        <Writer/>
      </div>
      </div>
      
    )
  }