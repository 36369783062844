import React from 'react'
import AllPosts from '../components/allPosts'
import { useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet";
import { useQuery, gql } from '@apollo/client'
import ReactGA from 'react-ga4';

const PG = gql`
query PG{
  blogspage{
    data{
      attributes{
        seo{
          metaTitle
          metaDescription
          metaViewport
          metaRobots
          structuredData
          keywords
          metaTwitterImage{
            data{
              attributes{
                url
              }
            }
          }
          metaImage{
            data{
              attributes{
                url
              }
            }
          }
          
        }
      }
    }
  }
}
`

export default function PostGrid() {
    const navigate = useNavigate();

    
    const handleSearch = (e) => {
      e.preventDefault();
      navigate(`/search/${e.target[0].value}`);
    };

    const { loading, error, data } = useQuery(PG)

    if (loading) return <p>Loading</p>
    if (error) return <p>{JSON.stringify(error)}</p>
    ReactGA.send("pageview");
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{data.blogspage.data.attributes.seo.metaTitle}</title>
          <meta name="description" content={data.blogspage.data.attributes.seo.metaDescription} />
          <link rel="canonical" href="/blogs" />
          <meta name="keywords" content={data.blogspage.data.attributes.seo.keywords}/>
          <meta name="author" content="Saumya Talwani"/>
          <meta property="og:title" content={data.blogspage.data.attributes.seo.metaTitle} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://techsaumya.in/blogs" />
          <meta property="og:image" content={data.blogspage.data.attributes.seo.metaTwitterImage.data.attributes.url}/>
          <meta property="og:description" content={data.blogspage.data.attributes.seo.metaDescription}/>
          <meta property="twitter:title" content="Home | TechSaumya" />
          <meta property="twitter:type" content="website" />
          <meta property="twitter:url" content="https://techsaumya.in/blogs" />
          <meta property="twitter:image" content={data.blogspage.data.attributes.seo.metaTwitterImage.data.attributes.url}/>
          <meta property="twitter:description" content={data.blogspage.data.attributes.seo.metaDescription}/>
          <meta name="robots" content={data.blogspage.data.attributes.seo.metaRobots}/>
          <script type="application/ld+json">{JSON.stringify(data.blogspage.data.attributes.seo.structuredData)}</script>
        </Helmet>
      <div className='p-10'>
        <h1 className='font-bold text-4xl pb-10 cgrot text-sky-300'>All Posts</h1>
        <form onSubmit={handleSearch}>
          <div className="flex items-center justify-center">
          <label htmlFor="location-search"
          className="mb-2 text-sm font-medium text-charcoal-900 sr-only"> Search </label>
          <div className="flex w-screen mb-5 justify-center">
            <input
              type="search"
              id='location-search"'
              className="w-[60vw] rounded-l-full rounded-r-none block p-2.5 z-20 text-sm border-l-2 border-r-0 border border-sky-400 focus:ring-sky-500 focus:border-sky-50 text-charcoal-700"
              placeholder="Search..."
              required="" />
            <button
              type="submit"
              className="p-2.5 text-sm font-medium text-gray-400 bg-milk rounded-r-full border border-sky-400 focus:ring-4 focus:border-sky-500 focus:ring-sky-500" >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="none"
                stroke="#4184e4"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z">
                </path>
              </svg>
              <span className="sr-only">Search</span>
            </button>
          </div>
        </div>
        </form>
        <AllPosts />
      </div>
    </>
    )
  }