import React from 'react'
import { Link } from 'react-router-dom'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function LCard(props) {
    return(
        <div>
            <Link to={props.link}>
                <div className='rounded-2xl bg-sky-500 w-[80vw] lg:w-screen h-[25vh] lg:h-[35vh] overflow-hidden shadow-md hover:bg-sky-200 container text-milk'>
                    <div style={{backgroundImage: `url(${props.image})`}} className='aspect-[0.9] lg:aspect-[1.15] h-full rounded-2xl bg-cover bg-center relative float-left'>
                        
                    </div>

                <div className='p-5 relative h-full overflow-auto'>
                    <p className='text-sm lg:text-base border rounded-full inline p-1 border-sky-100'>{props.ctg}</p>
                    <p className='py-3'> </p>
                    <span className='font-extrabold text-xl lg:text-3xl cgrot'>{props.title}</span>
                    <p className='py-3 hidden lg:grid'> </p>
                    <span className='block text-gray text-sm'>{props.desc}</span>
                    
                        <div className='rounded-full aspect-square h-8 w-8 bg-slate-300 opacity-40 hover:opacity-100 absolute bottom-4 right-4'>
                            <ArrowForwardIcon className='pl-[6px] text-charcoal-1000'/>
                        </div>   
                    
                </div>
                </div>
            </Link>
        </div>
    )
}