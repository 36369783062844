import React from 'react'
import { Link } from 'react-router-dom'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function Card(props) {
    return(
        <div>
            <Link to={props.link}>
            
        <div className='rounded-2xl my-15 text-milk bg-sky-500 lg:w-[28vw] overflow-hidden shadow-md max-w-md hover:bg-sky-200 container'>
            <div className='p-3 h-[25v]'>
                <p className='text-sm lg:text-base border rounded-full inline p-1 border-sky-100 '>{props.ctg}</p>
                <p className='py-2'> </p>
                <h2 className='font-extrabold text-3xl cgrot'>{props.title}</h2>
                <p className='py-3'> </p>
                <span className='block text-gray text-sm'>{props.desc}</span>
            </div>
            <div style={{backgroundImage: `url(${props.image})`}} className='w-full h-[36vh] rounded-2xl bg-cover bg-center relative'>
            
                <div className='rounded-full aspect-square h-8 w-8 bg-milk opacity-30 hover:opacity-100 absolute bottom-4 right-4'>
                <ArrowForwardIcon className='pl-[6px] text-charcoal-1000'/>
                </div>   
        
            </div>
            </div>
            </Link>
            
            </div>
    )
}