import React from 'react'
import { useQuery, gql } from '@apollo/client'


const WRITERS = gql`
query getWriters{
    writers{
      data{
        id
        attributes{
          name
          tagline
          bio
          LinkedIn
          picture{
            data{
              attributes{
                url
              }
            }
          }
        }
      }
    }
  }`
//const img = 'https://qph.cf2.quoracdn.net/main-thumb-456182455-200-flijkiimujfuviqnonbqzqnjwaqweill.jpeg'

export default function Writer(props) {
    const { loading, error, data } = useQuery(WRITERS)

    if (loading) return <p className='text-3xl'>Loading...</p>    
    if (error) return <p>Error: {JSON.stringify(error)}</p>;

    var writer=data.writers.data
    
    
    return(
        <div>

        
        {
            writer.map(wrt => (
                <div key={wrt.id} id="1" className='rounded-2xl w-[80vw] lg:w-screen h-[50vh] lg:h-[35vh] shadow-md container bg-charcoal-600 link'>
                <img className='p-5 aspect-square rounded-full h-[128px] lg:h-[192px] inline-block float-left' alt='writer-thumbnail' src={wrt.attributes.picture.data.attributes.url}/>
                <div className='p-5'>
                    <h1 className='font-bold text-3xl cgrot'>{wrt.attributes.name}</h1>
                    <a href={wrt.attributes.LinkedIn}>LinkedIn</a> 
                    <h2>{wrt.attributes.tagline}</h2>
                    <div className='lg:hidden'>
                      <br/>
                    </div>
                    <p>{wrt.attributes.bio}</p>
                </div>
                
                
            </div> 
            ))
        }
        </div>
        
    )
}
