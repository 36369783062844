import React from 'react'
/*
import { useQuery, gql } from '@apollo/client'

const HERO = gql`
query heroDetails{
    homepage{
      data{
        attributes{
          hero{
            title
            subText
          }
        }
      }
    }
  }`
*/
export default function hero() {

    //const { loading, error, data } = useQuery(HERO)

    //if (loading) return <p className='text-3xl'>Loading...</p>
    //if (error) return <p>Error: {JSON.stringify(error)}</p>;

    return(<div className='w-full h-[45vh] lg:h-[20vh]'>
        <div className='w-full flex flex-col justify-center px-5 py-5 relative '>
            <h1 className='text-5xl font-extrabold text-sky-400'>Your Destination for Tech.</h1> 
            <p className='py-3 text-milk'>The place to come for the Latest Tech Trends, Breaking News, and Insights to Fuel Your Digital Lifestyle</p>
        </div>
    </div>)
}
