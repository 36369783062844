import React from 'react'
//import Card from './Card'
import LCard from './LongCard'
//import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'

const POSTS = gql`
query getPosts{
  articles{
    data{
      id,
      attributes{
        title,
        description,
        content,
        slug,
        tags{
          data{
            attributes{
              name
            }
          }
        }
        publishedAt,
        thumb{
          data{
            attributes{
              url,
              formats
            }
          }
        }
      }
    }
  }
}`



export default function AllPosts() {

    const { loading, error, data } = useQuery(POSTS)

    if (loading) return <p className='text-3xl'>Loading...</p>
    if (error) return <p>Error: {JSON.stringify(error)}</p>;

    var res = data.articles.data

    return(<div className='w-full top[90px] px-3 lg:px-5 posts'>
        <div className='grid gap-10 lg:gap-5 pb-5'>
        {res.map(post => (
            //<Link to={`/blogs/${post.id}`}>
            <LCard key={post.id} title={post.attributes.title} desc={post.attributes.description} ctg={post.attributes.tags.data[0].attributes.name} image={post.attributes.thumb.data.attributes.url} link={`/blogs/${post.attributes.slug}`}/>
            //</Link>
        ))}
        </div>
    </div>)
}
